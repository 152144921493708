@charset 'UTF-8';

// 1. Configuration and helpers
@import 'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

.popup-notice-board,
.popup-notice-board-detail {
  .popup.middle {
    max-width: 63.3rem;
  }
}

// popup-notice-board 
.popup-notice-board {

  // Set Table cell width //
  $tbody-height:50rem; //12개리스트
  $table-height:$tbody-height + 5.2rem;
  .table-wrapper {
    &.notice-board {
      table {
        height: $table-height;
        tbody {
          height: $tbody-height;
        }
        th,
        td {
          &:nth-child(1) {
            width: 90px;
          }

          &:nth-child(2) {
            width: auto;
          }

          &:nth-child(3) {
            width: 25%;
          }
        }
      }
    }
  }
}

// popup-notice-board-detail
.popup-notice-board-detail {
  .popup.middle {
    height: 455px;
    .popup-container {
      height: 100%;
      .popup-content {
        min-height: calc(100% - 14rem);
      }
    }
  }
  .left-side {
    @include set-flex(row, flex-start, center);
  }
  .post-date {
      margin-left: 1.9rem;
      @include set-font(13.5px, 400, $color-gray-03, 3rem);
  }
  .btn-all-list {
    @include set-flex(row, center, center);
    min-width: 18rem;
    border: 2px solid $color-primary;
    @include set-font(13.5px, 700, $color-primary);
    &:before {
      content:'';
      display: block;
      margin-right: 9px;
      width: 16px;
      height: 10px;
      background: asset-image('arrow.svg') center no-repeat;
      background-size: 100% auto;
      text-indent: -9999px;
    }
  
  }
}


// .popup-notice-board